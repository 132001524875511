<template>
  <div>
    <AddUser :id="formState.id" />
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { useRoute } from "vue-router";
import AddUser from "../../pages/AddUser.vue";

export default defineComponent({
  components: {
    AddUser,
  },
  setup() {
    const router = useRoute();
    const formState = reactive({
      id: router.params.id,
    });

    return {
      formState,
    };
  },
});
</script>
<style scoped>
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
